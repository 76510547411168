import { RefObject, useState, useEffect } from 'react';

const useIntersection = (
  element: RefObject<HTMLElement | null>,
  rootMargin?: string,
  rootElement?: HTMLElement | null,
) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const elementRef = element.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin, root: rootElement },
    );

    elementRef && observer.observe(elementRef);

    if (elementRef) {
      return () => observer.unobserve(elementRef);
    }
  });

  return isVisible;
};

export default useIntersection;
