import React, { FC, ReactNode, useEffect } from 'react';
import { useUserContext } from 'src/context/user';
import { ROUTES } from 'src/constants/routes.const';
import { convertToMemriseError } from 'src/utils/errors';
import { makeUrlRelativeOrDashboard } from 'src/utils/location';
import useSafePush from 'src/hooks/useSafePush';
import * as S from './styles';

interface Props {
  slug?: string;
  children?: ReactNode;
}

const RedirectIfAuth: FC<Props> = ({ slug = ROUTES.dashboard, children }) => {
  const { error, user } = useUserContext();
  const shouldRedirect = !!user;

  const { safePush } = useSafePush();

  useEffect(() => {
    if (shouldRedirect) {
      void safePush(makeUrlRelativeOrDashboard(slug));
    }
  }, [slug, shouldRedirect, safePush]);

  // If there is an error, silently log with console.error
  // but show children
  useEffect(() => {
    if (error) {
      const memriseError = convertToMemriseError(error);
      const unauthorized = 401;
      if (memriseError.httpStatusCode !== unauthorized) {
        console.error(error);
      }
    }
  }, [error]);

  if (!error && shouldRedirect) {
    return (
      <S.Root>
        <S.Loader />
      </S.Root>
    );
  }

  return <>{children}</>;
};

export default RedirectIfAuth;
