import styled, { css } from 'styled-components';
import { Loader as LoaderComponent } from 'src/components';

export const Root = styled.div`
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled(LoaderComponent)(
  ({ theme }) => css`
    max-height: ${theme.sz.s40};
    margin: auto;
    display: block;
  `,
);
